<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Edit Dokter'">
          <template v-slot:body>
            <div>

              <Form
                :form="form"
                :route="'doctors/' + form.id"
                :password="false"
              />

            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/doctors/Form.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Form
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        sip_number: "",
        sip_exp: "",
        id_card_number: "",
        mobile_phone: "",
        phone: "",
        address: "",
        polyclinic_id: null,
        photo: [],
        photo_name: ""
      },
    }
  },

  methods: {

    async get() {
      this.form = await module.get('doctors/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/doctor/list')
      } else {
        this.form['_method'] = "put"
        this.form.photo_name = this.form.photo
        this.form.photo = []
        this.form['user_type_id'] = "1"
      }
    }

  },

  components: {
    Card,
    Form
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Daftar Dokter", route: "/doctor/list" },
      { title: "Edit" },
    ])
    // Get Data
    this.get()
  },

}

</script>